import React, { useEffect } from 'react';
import { Card, TextLocalized, ContainerFlex } from '../styled/Syled';

export const notificationDefault = {
    show: false,
    color: "red",
    message: ""
}

export const NotificationView = ({ noti, setNoti }) => {

    useEffect(() => {
        let timer = setTimeout(() => {
            setNoti({ ...noti, show: false });
        }, 3000)
        return () => clearTimeout(timer);
    })

    return noti.show ? <ContainerFlex width="12" justify="center" position="fixed" bottom="16px">
        <Card px="16" fontColor="white" mt="32" textAlign="center" color={noti.color}
            children={<TextLocalized children={noti.message} />} />
    </ContainerFlex> : null

}
