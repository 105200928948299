const es = {
  appName: 'SwingersApp',
  msgManager: 'Event Manager',
  msgEmail: 'Email',
  msgPassword: 'Password',
  msgPhone: 'Teléfono',

  actionLogin: 'Iniciar sesión',
  actionRegister: 'Si eres un club resgístrate aquí',
  actionForget: '¿Has olvidado tu contraseña?',
  actionLogOut: 'Cerrar sesión',
  actionAdd: 'Añadir',
  actionNew: 'Nuevo',
  actionPublish: 'Publicar',
  actionSave: 'Guardar',
  actionDelete: 'Eliminar',
  actionDetails: 'Detalles',
  actionNext: 'Siguiente',
  actionPrev: 'Anterior',
  actionConfirmPayment: 'Confirmar suscripción y procesar pago',

  msgEvents: 'Eventos',
  msgProfile: 'Perfil',
  msgEditors: 'Editores',

  msgInformationContact: 'Información de usuario',
  msgInformationClub: 'Información del club',
  msgInformation: 'Información',
  msgTickets: 'Entradas',
  msgCrowd: 'Asistentes',

  msgSections: 'Secciones',
  msgActions: 'Acciones',
  msgTitle: 'Título',
  msgName: 'Nombre',
  msgDescription: 'Descripción',
  msgDateTime: 'Fecha y hora',
  msgLocation: 'Ubicación',
  msgAddress: 'Dirección',
  msgAudience: 'Audiencia',
  msgRecomendations: 'Recomendaciones',
  msgSelect: 'Selecciona',
  msgPrice: 'Precio',
  msgOpen: 'Abierto',
  msgScheduler: 'Horario',
  msgActivities: 'Actividades',
  msgPaymentTitle: 'Alta en el servicio',

  msgMainImage: "Imagen",
  msgMainImageText: "Recomendación: JPEG o PNG 24 Bits (no transparente). Obligatorio: 500 x 1024 px",

  msgTicketsSubTitle: 'Los tipos de entradas solo podrán ser eliminados mientras no exista ninguna compra asociada a ellos.',

  msgSubscriptionYear: 'SUSCRIPCIÓN ANUAL',
  msgSubscriptionTerms: 'Renovación automática, cancelable en cualquier momento previo aviso con 24H de antelación',

  msgResumeContact: 'Resumen contacto',
  msgResumeClub: 'Resumen club',
  msgPaymentData: 'Datos de pago',
  msgAcceptCond: "Acepto la política de privacidad y los terminos y condiciones de uso",


  msgFilterBy: 'Filtrar por',

  errorLogin: 'Usuario y/o password incorrectos'
};

export default es;

export const webLandingUrl = "https://swingers-app.com";