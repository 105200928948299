import React, { useRef, useState } from 'react';
import { imageUrl } from '../util/HttpConnection';
import { ContainerFlex, Card, Container, TextLocalized, Button } from './Syled';

export const Input = (props) => {
    const { title, recomended, align = "center", children } = props;

    return <ContainerFlex width="10" pv="16" align={align}>
        <Container width="6" fontSize="14px" fontWeight="400" fontColor="white" children={<TextLocalized children={title} />} />
        <Container width="6" fontColor="white">
            <Card flex={true} shadow={false} width="12" border="solid" borderWidth="1px" borderColor="white" px="8">
                {children}
            </Card>
            {
                recomended ?
                    <Container textAlign="right" width="13" pv="8" fontSize="11px" fontWeight="400" children={recomended} />
                    : null
            }
        </Container>
    </ContainerFlex>
}

export const InputImage = (props) => {
    const { title, recomended, align = "center", image } = props;
    const { imagePreview, setImagePreview } = props;
    const [errorSize, setErrorSize] = useState(false);

    //Image
    const refInputFile = useRef(null);

    const _handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var image = new Image();
            image.src = URL.createObjectURL(file);

            image.onload = () => {
                var width = image.naturalWidth,
                    height = image.naturalHeight;

                console.log(width)
                console.log(height)

                URL.revokeObjectURL(image.src);

                if (height === 500 && width === 1024) {
                    setImagePreview({
                        file: file,
                        url: reader.result
                    });
                    setErrorSize(false);
                } else {
                    setErrorSize(true);
                }
            };
        }

        if (file !== null)
            reader.readAsDataURL(file)
    }

    return <ContainerFlex width="10" pv="16" align={align}>
        <Container width="6" fontSize="14px" fontWeight="400" children={<TextLocalized children={title} />} />
        <Container width="6">
            <Card flex={true} shadow={false} width="12" border="solid" borderWidth="1px" borderColor={errorSize ? "red" : "white"} px="8">
                {
                    (imagePreview && imagePreview.url) || image ?
                        <img alt="product"
                            width="100%"
                            style={{ paddingBottom: '16px' }}
                            src={imagePreview ? imagePreview.url : (image ? `${imageUrl}${image}` : "")} />
                        : null
                }
                <input ref={refInputFile} type="file" onChange={_handleImageChange} hidden />
                <Container width="12">
                    <Button flex={true} justify="center" onclick={() => refInputFile.current.click()} px="16"
                        color="colorAccent" fontColor="colorWhite" fontSize="13px" fontWeight="bold" children={<TextLocalized children="msgSelect" />} />
                </Container>
            </Card>
            <Container width="12" pv="16" fontSize="10px" fontWeight={errorSize ? "bold" : "400"} fontColor={errorSize ? "red" : "white"} children={<TextLocalized children={recomended} />} />
        </Container>
    </ContainerFlex>
}

export const InputField = (props) => {
    const { name, value, setValue, maxLenght } = props;
    const _innerOnChange = (e) => {
        if (e.target.value.length < maxLenght + 1) {
            setValue(e)
        }
    }
    return <Input {...props} recomended={maxLenght ? `${value.length}/${maxLenght}` : null} children={<input onChange={_innerOnChange} type="text" name={name} value={value} maxLength={maxLenght} />} />
}
export const TextAreaField = (props) => {
    const { rows = 2, name, value, setValue, maxLenght } = props;
    const _innerOnChange = (e) => {
        if (e.target.value.length < maxLenght + 1) {
            setValue(e)
        }
    }
    return <Input {...props} recomended={`${value.length}/${maxLenght}`} align="flex-start" children={<textarea onChange={_innerOnChange} rows={rows} name={name} type="text" value={value} maxLength={maxLenght} />} />
}

