export const AudiencesEnable = [
    { id: 1, name: "Hombres" },
    { id: 2, name: "Mujeres" },
    { id: 3, name: "Parejas hetero" },
    { id: 4, name: "Parejas hombres" },
    { id: 5, name: "Parejas mujeres" },
    { id: 6, name: "CrossDresser" },
    { id: 7, name: "Transexual" },
];

export const TicketDefault = {
    name: '',
    description: '',
    price: 0
}

export const ClubDefault = {
    "name": "",
    "description": "",
    "open_days": "",
    "schedule": "",
    "address": "",
    "info": "",
    "audiences": [],
    "images": [],
}