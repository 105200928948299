import React, { Fragment, useContext, useEffect } from 'react';
import './css/normalize.css';
import 'react-datepicker/dist/react-datepicker.css';
import './css/App.css';
import './css/bootstrap-grid.css';
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { LanguageProvider } from './components/providers/LanguageProvider';
import { UserContext, UserPovider } from './components/providers/UserProvider';
import FooterView from './components/view/FooterView';
import { ContainerFlex } from './components/styled/Syled';
import LoginView from './components/view/LoginView';
import HeaderView from './components/view/HeaderView';
import EventsView from './components/view/event/EventsView';
import EventDetailView from './components/view/event/EventDetailView';
import ClubDetailView from './components/view/club/ClubDetailView';
import RegisterView from './components/view/RegisterView';

const ScrollTop = ({ children }) => {
  let history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, [history]);

  return <Fragment>{children}</Fragment>;
}

const SwitchView = () => {
  const { userSettings } = useContext(UserContext);

  useEffect(() => {
    console.log(userSettings)
  })

  return (
    <Fragment>
      <Router basename="/">
        {
          userSettings.login ?
            <Fragment>
              <HeaderView />
              <Switch>
                <ScrollTop>
                  <Route exact={true} path="/" component={EventsView} />
                  <Route exact={true} path="/event/:id" component={EventDetailView} />
                  <Route exact={true} path="/profile" component={ClubDetailView} />
                </ScrollTop>
              </Switch>
            </Fragment>
            : <Fragment>
              <Switch>
                <Route exact={true} path="/" component={LoginView} />
                <Route exact={true} path="/register" component={RegisterView} />
              </Switch>
            </Fragment>
        }
      </Router>
    </Fragment >
  )
}

const App = () => {
  return (
    <LanguageProvider>
      <UserPovider>
        <ContainerFlex width="12" justify="center">
          <SwitchView />
          <FooterView />
        </ContainerFlex>
      </UserPovider>
    </LanguageProvider>
  )
}

export default App;
