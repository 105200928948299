import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../providers/LanguageProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../../styled/Syled';
import logo from './../../../images/img/demo.png';
import Icon from '@mdi/react'
import { mdiAccountGroup, mdiCalendar } from '@mdi/js'

const EventsFilterView = ({ filter, setFilter }) => {

    let history = useHistory();
    const textContext = useContext(LanguageContext);
    const [formData, setFormData] = useState({ ...filter })

    const _onchangeFrom = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Container width="12" justify="center" fontColor="white">
            <ContainerFlex width="12" justify="flex-start" fontWeight="bold" fontSize="25px" fontSizeLg="35px" ph="32" pv="16" textAlign="center" children={`3 ${textContext.dictionary["msgEvents"]}`} />

            <ContainerFlex width="12" ph="32">
                <ContainerFlex width="wrap" justify="flex-start" align="center" fontWeight="500">
                    <TextLocalized children="msgFilterBy" />
                    <Card shadow={false} width="auto" color="colorWhite" ph="16" pv="4" mh="16" fontColor="white" fontSize="18px" border="solid" borderColor="white" borderWidth="1px" radius="50px">
                        <select name="state" style={{ width: "100%", color: "var(--white)" }} value={formData.category} onChange={_onchangeFrom} >
                            <option value="">Estado</option>
                        </select>
                    </Card>
                </ContainerFlex>
                <Button width="auto" onclick={() => history.push("/event/1")} color="colorAccent"
                    fontWeight="bold" fontColor="white" ph="32" pv="9" radius="24px" children={`${textContext.dictionary["actionNew"]}`} />
            </ContainerFlex>
        </Container>
    );
}

const EventItemView = ({ item }) => {
    let history = useHistory();


    return <Container width="3" px="8">
        <Button onclick={() => history.push("/event/1")} flex={true} color="white" align="stretch">
            <Card image={logo} imgPosition="top" minwidth="100px" minheight="100%" />
            <Container width="wrap" pt="8" ph="8">
                <Container width="12" px="8" fontWeight="bold" children="Fiesta Hallowen Post Pandemia" />
                <Card flex={true} width="auto" align="center" color="colorAccent" px="8" mx="8" fontColor="white">
                    <Icon path={mdiCalendar} size="20px" />
                    <Container fontSize="12px" ph="8" children="Fecha: 30/10/2020 23:59" />
                </Card>
                <Card flex={true} align="center" color="colorPrimary" px="8" mx="8" fontColor="white">
                    <Icon path={mdiAccountGroup} size="20px" />
                    <Container fontSize="12px" ph="8" children="10 asistentes" />
                </Card>
            </Container>
        </Button>
    </Container>
}

const EventsView = () => {
    return (
        <Container width="12" minheight="calc(100vh - 96px)">
            <EventsFilterView />
            <ContainerFlex width="12" px="26" align="stretch">
                {
                    [1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => <EventItemView key={index} />)
                }
            </ContainerFlex>
        </Container>
    );
};

export default EventsView;