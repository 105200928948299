import React, { useState, useContext } from 'react';
import { ContainerFlex, Container, TextLocalized, Card, Button } from '../styled/Syled';
import logo from './../../images/img/icon.png';
import { LanguageContext } from '../providers/LanguageProvider';
import { notificationDefault, NotificationView } from '../util/NotificationUtil';
import { HttpConnection, HttpOperations, HttpMethod } from '../util/HttpConnection';
import { UserContext, UserAction } from '../providers/UserProvider';
import { useHistory } from 'react-router-dom';
import { InputField } from '../styled/FormUitl';
import { ClubDefault } from '../util/ConstantUtil';
import { ClubInfoView } from './club/ClubDetailView';

const loginData = {
    "username": '',
    "password": '',
    "name": '',
    "phone": ''
}

const RegisterPaymentView = ({ prevStep }) => {

    const [acceptTerms, setAcceptTerms] = useState(false);

    return <ContainerFlex width="10" justify="center">
        <ContainerFlex width="12" align="center" justify="space-between" px="32" pb="0">
            <Container fontWeight="bold" fontSize="28px" fontColor="white" children={<TextLocalized children="msgPaymentTitle" />} />
            <ContainerFlex>
                <Button onclick={() => prevStep()} ph="16" pv="8" mh="4" textAlign="center" fontColor="colorAccent" fontWeight="bold" color="white" children={<TextLocalized children="actionPrev" />} />
            </ContainerFlex>
        </ContainerFlex>

        <ContainerFlex width="12">
            <Container maxwidth="400px" pv="32" ph="16">
                <Card width="12" color="white" px="16">
                    <Container width="12" fontSize="20px" fontWeight="bold" pb="32" textAlign="center" children={<TextLocalized children="msgResumeContact" />} />
                    <Container width="12" pv="8" fontSize="14px">
                        <Container fontWeight="bold" children={<TextLocalized children="msgName" />} />
                        <Container children={"Nombre y Apellidos"} />
                    </Container>
                    <Container width="12" pv="8" fontSize="14px">
                        <Container fontWeight="bold" children={<TextLocalized children="msgPhone" />} />
                        <Container children={"666666666"} />
                    </Container>
                </Card>
                <Card width="12" color="white" px="16" mv="16">
                    <Container width="12" fontSize="20px" fontWeight="bold" pb="32" textAlign="center" children={<TextLocalized children="msgResumeClub" />} />
                    <Container width="12" pv="8" fontSize="14px">
                        <Container fontWeight="bold" children={<TextLocalized children="msgName" />} />
                        <Container children={"Nombre del local"} />
                    </Container>
                    <Container width="12" pv="8" fontSize="14px">
                        <Container fontWeight="bold" children={<TextLocalized children="msgDescription" />} />
                        <Container children={"Descripción del local"} />
                    </Container>
                    <Container width="12" pv="8" fontSize="14px">
                        <Container fontWeight="bold" children={<TextLocalized children="msgAddress" />} />
                        <Container children={"C/Prueba 13, Madrid (20010)"} />
                    </Container>
                </Card>
                <Card flex={true} width="auto" justify="center" color="white" px="16">
                    <Container width="12" fontSize="20px" fontWeight="bold" textAlign="center" children={<TextLocalized children="msgSubscriptionYear" />} />
                    <Container ph="8" pv="48" fontSize="60px" fontWeight="bold" textAlign="center" children="49.99 €" />
                    <Container width="12" fontSize="12px" fontWeight="400" textAlign="center" children={<TextLocalized children="msgSubscriptionTerms" />} />
                </Card>
            </Container>
            <Container width="wrap">
                <Container width="12" textAlign="center" fontSize="20px" fontWeight="bold" pv="32" fontColor="white" children={<TextLocalized children="msgPaymentData" />} />
                <ContainerFlex width="12" justify="center" align="center" fontColor="white" fontSize="12px" mv="16">
                    <input width="24" height="24" type="checkbox" name="terms" value={acceptTerms} onChange={(e) => setAcceptTerms(e.target.checked)} />
                    <Button onclick={() => window.open(`./terms`, '_blank')} shadow={false} ml="8" children={<TextLocalized children="msgAcceptCond" />} />
                </ContainerFlex>
                <ContainerFlex justify="center">
                    <Button  ph="32" pv="16" mv="32" textAlign="center" fontColor="white" fontWeight="bold" color="colorAccent" children={<TextLocalized children="actionConfirmPayment" />} />
                </ContainerFlex>
            </Container>
        </ContainerFlex>
    </ContainerFlex>
}

const RegisterClubInfo = ({ nextStep, prevStep }) => {
    const [formData, setFormData] = useState(ClubDefault);

    return <ContainerFlex width="10" justify="center">
        <ContainerFlex width="12" align="center" justify="space-between" px="32" pb="0">
            <Container fontWeight="bold" fontSize="28px" fontColor="white" children={<TextLocalized children="msgInformationClub" />} />
            <ContainerFlex>
                <Button onclick={() => prevStep()} ph="16" pv="8" mh="4" textAlign="center" fontColor="colorAccent" fontWeight="bold" color="white" children={<TextLocalized children="actionPrev" />} />
                <Button onclick={() => nextStep()} ph="16" pv="8" mh="4" textAlign="center" fontColor="colorAccent" fontWeight="bold" color="white" children={<TextLocalized children="actionNext" />} />
            </ContainerFlex>
        </ContainerFlex>
        <ClubInfoView item={formData} />
    </ContainerFlex>
}

const RegisterUserManager = ({ nextStep }) => {

    const [formData, setFormData] = useState(loginData);

    //Form managment
    const changeTextData = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    return <Container width="10">
        <Container width="12" color="colorWhite" fontColor="white" px="32">
            <ContainerFlex width="12" align="center" justify="space-between" pb="32">
                <Container fontWeight="bold" fontSize="28px" children={<TextLocalized children="msgInformationContact" />} />
                <Button onclick={() => nextStep()} ph="16" pv="8" textAlign="center" fontColor="colorAccent" fontWeight="bold" color="white" children={<TextLocalized children="actionNext" />} />
            </ContainerFlex>
            <InputField title="msgEmail" name="name" value={formData.username} setValue={changeTextData} maxLenght="50" />
            <InputField title="msgPassword" name="password" value={formData.password} setValue={changeTextData} maxLenght="50" />
        </Container>

        <Container width="12" color="colorPrimary" px="32">
            <InputField title="msgName" name="open_days" value={formData.name} setValue={changeTextData} maxLenght="20" />
            <InputField title="msgPhone" name="schedule" value={formData.phone} setValue={changeTextData} maxLenght="13" />
        </Container>
    </Container>
}

const RegisterView = () => {
    const [currentStep, setCurrentStep] = useState(0);
    let history = useHistory();

    const nextStep = () => setCurrentStep(currentStep < 2 ? currentStep + 1 : 2);
    const prevStep = () => setCurrentStep(currentStep > 0 ? currentStep - 1 : 0);

    const circleColor = (index) => index === currentStep ? "colorAccent" : "colorPrimaryDark";

    return (
        <Container width="12">
            <ContainerFlex width="12" ph="32" pv="8" justify="space-between" align="center">
                <ContainerFlex pv="8" align="center">
                    <img alt="logo" src={logo} width="64" />
                    <Container ml="16">
                        <Container fontColor="colorAccent" fontSize="22px" fontWeight="300" children={<TextLocalized children="appName" />} />
                        <Container fontColor="white" fontSize="12px" fontWeight="bold" children={<TextLocalized children="msgManager" />} />
                    </Container>
                </ContainerFlex>
                <ContainerFlex fontColor="white">
                    <Button fontHoverColor="colorAccent" onclick={() => history.goBack()} shadow={false} ph="32" children={<TextLocalized children="actionLogin" />} />
                </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex width="12" minheight="calc(100vh - 92px)" justify="flex-start" align="center" direction="column" color="colorPrimaryDark">

                <ContainerFlex align="center" pv="32">
                    <Card flex={true} radius="50%" fontColor="white" minheight="48px" minwidth="48px" color={circleColor(0)} justify="center" align="center" border="solid" borderWidth="1px" borderColor="white" children="1" />
                    <Container minwidth="30px" minheight="2px" color="white" />
                    <Card flex={true} radius="50%" fontColor="white" minheight="48px" minwidth="48px" color={circleColor(1)} justify="center" align="center" border="solid" borderWidth="1px" borderColor="white" children="2" />
                    <Container minwidth="30px" minheight="2px" color="white" />
                    <Card flex={true} radius="50%" fontColor="white" minheight="48px" minwidth="48px" color={circleColor(2)} justify="center" align="center" border="solid" borderWidth="1px" borderColor="white" children="3" />
                </ContainerFlex>

                {
                    currentStep === 0 ?
                        <RegisterUserManager nextStep={nextStep} />
                        : currentStep === 1 ? <RegisterClubInfo nextStep={nextStep} prevStep={prevStep} />
                            : <RegisterPaymentView nextStep={nextStep} prevStep={prevStep} />
                }


            </ContainerFlex>
        </Container>

    );
};

export default RegisterView;