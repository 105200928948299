import { UserData } from '../providers/UserProvider';
import AES from 'crypto-js/aes';
import encUTF8 from 'crypto-js/enc-utf8';

const my_key = "s3cr3tk3y";

export const checkLoginUser = (user) => {
    var storageUser = getStorageUser();
    return storageUser && storageUser.usuario.id === user.id;
}

export const updateStorageUser = (user) => {
    var local = getStorageUser()
    user.usuario.password = local.usuario.password;
    
    var encrypted = AES.encrypt(JSON.stringify(user), my_key);
    localStorage.setItem(UserData.user, encrypted);
}

export const saveStorageUser = (user) => {
    var encrypted = AES.encrypt(JSON.stringify(user), my_key);
    localStorage.setItem(UserData.user, encrypted);
}

export const getStorageUser = () => {
    var encrypted = localStorage.getItem(UserData.user);
    if (encrypted) {
        try {
            var bytes = AES.decrypt(encrypted, my_key);
            var user = JSON.parse(bytes.toString(encUTF8))
            return user ? user : null
        } catch (error) {
            console.log(error)
            localStorage.removeItem(UserData.user)
            console.error("unknow user")
        }
    }
    return null;
}

export const getUserToken = () => {
    var storageUser = getStorageUser();
    return (storageUser) ? storageUser.token : "";
}