import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserAction, UserContext } from '../providers/UserProvider';
import { Button, Container, ContainerFlex, TextLocalized } from '../styled/Syled';
import logo from './../../images/img/icon.png';


const HeaderView = () => {
    const history = useHistory();
    const { dispatch } = useContext(UserContext);

    return (
        <ContainerFlex width="12" ph="32" pv="8" justify="space-between" align="center">
            <ContainerFlex pv="8" align="center">
                <img alt="logo" src={logo} width="64" />
                <Container ml="16">
                    <Container fontColor="colorAccent" fontSize="22px" fontWeight="300" children={<TextLocalized children="appName" />} />
                    <Container fontColor="white" fontSize="12px" fontWeight="bold" children={<TextLocalized children="msgManager" />} />
                </Container>
            </ContainerFlex>
            <ContainerFlex fontColor="white">
                <Button fontHoverColor="colorAccent" onclick={() => history.push("../")} shadow={false} ph="16" children={<TextLocalized children="msgEvents" />} />
                <Button fontHoverColor="colorAccent" onclick={() => history.push("../profile")} shadow={false} ph="16" children={<TextLocalized children="msgProfile" />} />

                <Button fontHoverColor="colorAccent" onclick={() => dispatch({ action: UserAction.logout })} shadow={false} ph="32" children={<TextLocalized children="actionLogOut" />} />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default HeaderView;