import { mdiInformation } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import { Input, InputField, InputImage, TextAreaField } from '../../styled/FormUitl';
import { Button, Container, ContainerFlex, TextLocalized } from '../../styled/Syled';
import { AudiencesEnable } from '../../util/ConstantUtil';

const demo = {
    "id": 1,
    "name": "Encuentros VIP",
    "description": "Bienvenidos a la mejor discoteca liberal de Madrid, con un exclusivo y divertido ambiente swinger, donde podrás disfrutar de momentos únicos y satisfacer tus más íntimos deseos.",
    "open_days": "Todos los días",
    "schedule": "16:00 - 06:00",
    "address": "Calle Vicente Caballero, 14, Madrid (España)",
    "info": "Swinger, BDSM y Mazmorras",
    "audiences": [
        {
            "id": 1,
            "name": "Hombre"
        },
        {
            "id": 2,
            "name": "Mujer"
        },
        {
            "id": 3,
            "name": "Pareja hetero"
        },
        {
            "id": 5,
            "name": "Pareja mujeres"
        },
        {
            "id": 7,
            "name": "Transexual"
        }
    ],
    "events": [],
    "images": [
        {
            "id": 3,
            "url": "club_1.png",
            "order": 0
        }
    ],
    "followers": []
}

export const ClubInfoView = ({ item }) => {
    const [formData, setFormData] = useState({ ...item });

    const [audiences, setAudiences] = useState([]);
    const [imagePreview, setImagePreview] = useState();

    //Form managment
    const changeTextData = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    //Audience managment
    const AudienceItem = ({ audience }) => {
        const isIncluded = () => audiences.filter((aud) => aud.id === audience.id).length > 0;
        return <Button radius="30px" mx="16" ph="32" pv="16" onclick={() => _changeAudience(audience)} color={isIncluded() ? "colorAccent" : "accent_alpha_60"} fontColor="white" fontWeight="500" fontSize="14px" children={audience.name} />
    }

    const _changeAudience = (audience) => {
        let auxAudiences = [...audiences]
        if (audiences.filter((aud) => aud.id === audience.id).length > 0) {
            auxAudiences = auxAudiences.filter((aud) => aud.id !== audience.id);
        } else {
            auxAudiences.push(audience);
        }
        setAudiences(auxAudiences);
    }

    return <Container width="12">
        <Container width="12" color="colorWhite" fontColor="white" px="32">
            <InputField title="msgName" name="name" value={formData.name} setValue={changeTextData} maxLenght="50" />
            <TextAreaField title="msgDescription" name="description" value={formData.description} setValue={changeTextData} rows="6" maxLenght="300" />
        </Container>

        <Container width="12" color="colorPrimary" px="32">
            <InputField title="msgOpen" name="open_days" value={formData.open_days} setValue={changeTextData} maxLenght="20" />
            <InputField title="msgScheduler" name="schedule" value={formData.schedule} setValue={changeTextData} maxLenght="13" />
            <InputField title="msgLocation" name="address" value={formData.address} setValue={changeTextData} maxLenght="50" />

            <Input title="msgAudience">
                {AudiencesEnable.map(aud => <AudienceItem key={aud.id} audience={aud} />)}
            </Input>
            <TextAreaField title="msgActivities" name="info" value={formData.info} setValue={changeTextData} rows="3" maxLenght="300" />
        </Container>

        <Container width="12" fontColor="white" px="32">
            <InputImage title="msgMainImage" recomended="msgMainImageText" align="flex-start" imagePreview={imagePreview} setImagePreview={setImagePreview} />
        </Container>
    </Container>
}

const ClubDetailView = () => {
    const [tabIndex, setTabIndex] = useState(0)
    const tabFontWeight = (index) => tabIndex === index ? 300 : 100;

    return (
        <ContainerFlex width="12" minheight="calc(100vh - 92px)" align="stretch">

            <Container width="2" minheight="100%" px="16">
                <Container px="16" textAlign="start" fontColor="white" fontWeight="bold" fontSize="24px" children={<TextLocalized children="msgSections" />} />

                <Button onclick={() => setTabIndex(0)} shadow={false} flex={true} align="center" pv="16" fontColor="white" hoverColor="colorPrimary" fontWeight={tabFontWeight(0)} radius="0px" radiustr="30px" radiusbr="30px">
                    <Icon path={mdiInformation} size="24px" style={{ margin: '0 16px' }} />
                    <TextLocalized children="msgInformation" />
                </Button>

            </Container>

            <ContainerFlex width="wrap" direction="column" minheight="100%" px="16">
                <ContainerFlex width="10" align="center" justify="space-between" px="32" pb="0">
                    <Container fontWeight="bold" fontColor="white" fontSize="28px" children={<TextLocalized children="msgInformation" />} />
                    <Button ph="16" pv="8" textAlign="center" fontColor="colorAccent" fontWeight="bold" color="white" children={<TextLocalized children="actionSave" />} />
                </ContainerFlex>
                <ClubInfoView item={demo} />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default ClubDetailView;