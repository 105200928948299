import React, { useState, useContext } from 'react';
import { ContainerFlex, Container, TextLocalized, Card, Button } from '../styled/Syled';
import logo from './../../images/img/icon.png';
import { LanguageContext } from '../providers/LanguageProvider';
import { notificationDefault, NotificationView } from '../util/NotificationUtil';
import { HttpConnection, HttpOperations, HttpMethod } from '../util/HttpConnection';
import { UserContext, UserAction } from '../providers/UserProvider';
import { useHistory } from 'react-router-dom';

const loginData = {
    "username": '',
    "password": ''
}

const LoginView = () => {
    const [formData, setFormData] = useState(loginData);
    const [notification, setNotification] = useState(notificationDefault);

    const { dispatch } = useContext(UserContext);

    const textContext = useContext(LanguageContext);

    let history = useHistory();

    const _onchangeFrom = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const _submitForm = () => {
        //Hide notification
        setNotification({
            ...notification,
            show: false
        })

        if (formData.email === "" || formData.password === "") {
            setNotification({
                ...notification,
                show: true,
                message: "errorOblData"
            })
        } else {
            if (formData.username === "admin@swingermanager.es" && formData.password === "1234")
                dispatch({ action: UserAction.login, data: formData })
            else
                setNotification({
                    ...notification,
                    show: true,
                    message: "errorLogin"
                })
            /*
            HttpConnection(HttpOperations.login,
                (item) => {
                    console.log(item)
                    item.usuario.password = formData.password;
                    dispatch({ action: UserAction.login, data: item })
                }, (error) => {
                    setNotification({
                        ...notification,
                        show: true,
                        message: "errorLogin"
                    })
                }, HttpMethod.post, formData)
                */
        }
    }

    return (
        <ContainerFlex width="12" minheight="100vh" justify="center" align="center" direction="column" color="colorPrimaryDark">

            <ContainerFlex pv="32" align="center">
                <img alt="logo" src={logo} width="125" />
                <Container ml="16">
                    <Container fontColor="colorAccent" fontSize="40px" fontWeight="300" children={<TextLocalized children="appName" />} />
                    <Container fontColor="white" fontWeight="bold" children={<TextLocalized children="msgManager" />} />
                </Container>
            </ContainerFlex>

            <ContainerFlex direction="column" width="12" lg="4" px="16" justify="center" align="center" fontColor="black">
                <Card width="12" color="white" mv="8" px="16" fontColor="black" fontSize="16px">
                    <input type="text" style={{ color: "black" }} placeholder={textContext.dictionary['msgEmail']} name="username" value={formData.username} onChange={_onchangeFrom} />
                </Card>

                <Card width="12" color="white" mt="16" px="16" fontColor="black" fontSize="16px">
                    <input type="password" style={{ color: "black" }} placeholder={textContext.dictionary['msgPassword']} name="password" value={formData.password} onChange={_onchangeFrom} />
                </Card>

                <NotificationView noti={notification} setNoti={setNotification} />

                <Button shadow={false} fontWeight="300" px="16" mv="16" fontColor="white" children={<TextLocalized children='actionForget' />} />
                <Button onclick={_submitForm} color="colorAccent" fontColor="white" fontWeight="bold" ph="32" pv="16" children={<TextLocalized children="actionLogin" />} />
                <Button onclick={() => history.push('../register')} shadow={false} px="16" mv="16" fontColor="white" children={<TextLocalized children='actionRegister' />} />
            </ContainerFlex>


        </ContainerFlex>
    );
};

export default LoginView;