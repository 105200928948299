import React from 'react';
import { ContainerFlex } from '../styled/Syled';

const FooterView = () => {
    return (
        <ContainerFlex width="12" px="16" color="colorAccent">
            
        </ContainerFlex>
    );
};

export default FooterView;