import React,{useState, useContext} from 'react';

import es from '../../constantes/es';

export const dictionaryList = {
    es,
};

export const languageOptions = [
    { id: 'es', text: 'Spanish' },
    { id: 'en', text: 'English' },
];

export const LanguageContext = React.createContext({
    language: languageOptions[0],
    dictionary: dictionaryList[languageOptions[0].id]
  });

  // it provides the language context to app
export function LanguageProvider(props) {
  
    const languageContext = useContext(LanguageContext);
    const [language, setLanguage] = useState(languageContext.language);
    const [dictionary, setDictionary] = useState(languageContext.dictionary);
  
    const provider = {
      language,
      dictionary,
      setLanguage: (selectedLanguage) => {
        setLanguage(selectedLanguage); // it will update the language in state
        setDictionary(dictionaryList[selectedLanguage.id]);
      }
    };
  
    return (
      <LanguageContext.Provider value={provider}>
        {props.children}
      </LanguageContext.Provider>
    );
  };