import { mdiAccountGroup, mdiArrowLeft, mdiInformation, mdiTicket } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../providers/LanguageProvider';
import { Input, InputField, InputImage, TextAreaField } from '../../styled/FormUitl';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../../styled/Syled';
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { AudiencesEnable, TicketDefault } from '../../util/ConstantUtil';

registerLocale('es', es)

const demo = {
    "id": 2,
    "title": "Proximamente",
    "description": "Disfruta de los eventos más exclusivos.",
    "address": "España",
    "date_publish": "2020-01-24 11:00:28",
    "date": "2020-12-30 14:00:00",
    "info": "Sin límite",
    "tickets": [
        {
            "id": 1,
            "name": "Pareja M-M",
            "description": "2 consumiciones",
            "price": 12.99
        },
        {
            "id": 2,
            "name": "Pareja H-M",
            "description": "1 consumiciones",
            "price": 20
        }
    ],
    "club": null,
    "user": {
        "id": 1,
        "username": "SwingersApp"
    },
    "images": [
        {
            "id": 2,
            "url": "app_eventos.png",
            "order": 0
        }
    ],
    "crowds": [
        {
            "id": 1,
            "username": "SwingersApp",
            "tickets": [
                {
                    "token": "123456Q",
                    "ticket": {
                        "id": 1,
                        "name": "Pareja M-M"
                    }
                }
            ]
        }
    ]
}

const EventInfo = ({ item }) => {
    const [formData, setFormData] = useState({ ...item });

    const [dateSelected, setDateSelected] = useState();
    const [audiences, setAudiences] = useState([]);

    const [imagePreview, setImagePreview] = useState();

    //Form managment
    const changeTextData = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    //Date managment
    const isToday = () => {
        let now = new Date();
        return dateSelected && dateSelected.getMonth() === now.getMonth() && dateSelected.getDate() === now.getDate();
    }

    const _dateChange = (date, mode = "date") => {
        var refreshDate = dateSelected ? dateSelected : new Date();
        refreshDate = new Date((mode === "date")
            ? refreshDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate())
            : refreshDate.setHours(date.getHours(), date.getMinutes(), 0, 0)
        );
        setDateSelected(refreshDate);
    }

    //Audience managment
    const AudienceItem = ({ audience }) => {
        const isIncluded = () => audiences.filter((aud) => aud.id === audience.id).length > 0;
        return <Button radius="30px" mx="16" ph="32" pv="16" onclick={() => _changeAudience(audience)} color={isIncluded() ? "colorAccent" : "accent_alpha_60"} fontColor="white" fontWeight="500" fontSize="14px" children={audience.name} />
    }

    const _changeAudience = (audience) => {
        let auxAudiences = [...audiences]
        if (audiences.filter((aud) => aud.id === audience.id).length > 0) {
            auxAudiences = auxAudiences.filter((aud) => aud.id !== audience.id);
        } else {
            auxAudiences.push(audience);
        }
        setAudiences(auxAudiences);
    }

    return <Container width="12">
        <Container width="12" color="colorWhite" fontColor="white" px="32">
            <ContainerFlex align="center" justify="space-between" pb="32">
                <Container fontWeight="bold" fontSize="28px" children={<TextLocalized children="msgInformation" />} />
                <Button ph="16" pv="8" textAlign="center" fontColor="colorAccent" fontWeight="bold" color="white" children={<TextLocalized children="actionSave" />} />
            </ContainerFlex>
            <InputField title="msgTitle" name="title" value={formData.title} setValue={changeTextData} maxLenght="50" />
            <TextAreaField title="msgDescription" name="description" value={formData.description} setValue={changeTextData} rows="6" maxLenght="300" />
        </Container>

        <Container width="12" color="colorPrimary" px="32">
            <InputField title="msgLocation" name="address" value={formData.address} setValue={changeTextData} maxLenght="50" />

            <Input title="msgDateTime">
                <Container width="6" pr="8">
                    <Card width="12" shadow={false} border="solid" borderWidth="1px" borderColor="colorGrayLight" fontColor="colorGrayLight" fontSize="18px" ph="16">
                        <DatePicker
                            locale="es"
                            dateFormat="dd/MM/yyyy"
                            showPopperArrow={false}
                            selected={dateSelected}
                            minDate={new Date()}
                            onChange={date => _dateChange(date)}
                            placeholderText="Fecha"
                            className="input-date"
                        />
                    </Card>
                </Container>
                <Container width="6" pl="8">
                    <Card width="12" shadow={false} border="solid" borderWidth="1px" borderColor="colorGrayLight" fontColor="colorGrayLight" fontSize="18px" ph="16">
                        <DatePicker
                            locale="es"
                            dateFormat="HH:mm"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption="Hora"
                            showPopperArrow={false}
                            selected={dateSelected}
                            minTime={isToday() ? new Date() : (new Date()).setHours(0, 0)}
                            maxTime={(new Date()).setHours(23, 0)}
                            onChange={date => _dateChange(date, "time")}
                            placeholderText="Hora"
                            className="input-date"
                        />
                    </Card>
                </Container>
            </Input>

            <Input title="msgAudience">
                {AudiencesEnable.map(aud => <AudienceItem key={aud.id} audience={aud} />)}
            </Input>
            <TextAreaField title="msgRecomendations" name="info" value={formData.info} setValue={changeTextData} rows="3" maxLenght="300" />
        </Container>

        <Container width="12" fontColor="white" px="32">
            <InputImage title="msgMainImage" recomended="msgMainImageText" align="flex-start" imagePreview={imagePreview} setImagePreview={setImagePreview} />
        </Container>
    </Container>
}

const TicketItemAdd = () => {
    const [formData, setFormData] = useState(TicketDefault);

    //Form managment
    const changeTextData = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const addTicketType = () => {

    }

    return <Container>
        <InputField title="msgTitle" name="name" value={formData.name} setValue={changeTextData} maxLenght="64" />
        <InputField title="msgDescription" name="description" value={formData.description} setValue={changeTextData} maxLenght="128" />
        <InputField title="msgPrice" name="price" value={formData.price} setValue={changeTextData} />
        <ContainerFlex width="12">
            <Button onclick={addTicketType} width="auto" color="colorAccent" fontColor="colorWhite" textAlign="center"
                fontWeight="bold" mv="16" pv="8" ph="64" children={<TextLocalized children="actionAdd" />} />
        </ContainerFlex>
    </Container>
}

const TicketItemView = ({ ticket }) => {

    const _deleteItem = () => {

    }

    return <Container width="6" px="8">
        <Card flex={true} width="12" color="white" align="center">
            <ContainerFlex width="wrap" fontColor="black" ph="8" align="center">
                <Container width="wrap" pv="16">
                    <Container fontWeight="bold" children={ticket.name} />
                    <Container pt="8" fontSize="14px" children={ticket.description} />
                </Container>

                <Container width="auto" children={`${ticket.price.toFixed(2)} €`} />
            </ContainerFlex>
            <Button onclick={_deleteItem} width="auto" color="red" fontColor="colorWhite" textAlign="center" fontWeight="bold" px="16" mx="4" children={<TextLocalized children="actionDelete" />} />
        </Card>
    </Container>
}


const EventTickets = ({ item }) => {
    const [tickets, setTickets] = useState([...item.tickets]);

    return <Container width="12" fontColor="white">
        <Container width="12" px="32">
            <Container width="12" fontWeight="bold" fontSize="28px" pb="16" children={<TextLocalized children="msgTickets" />} />
            <Container width="12" fontSize="14px" pb="32" children={<TextLocalized children="msgTicketsSubTitle" />} />

            <TicketItemAdd />

            <Container width="12" mv="16" height="1" color="white" />

            <ContainerFlex whidth="12" pv="16">
                {tickets.map(ticket => <TicketItemView key={ticket.id} ticket={ticket} />)}
            </ContainerFlex>
        </Container>
    </Container>
}

const CrowdTicketItemView = ({ item }) => {
    return <ContainerFlex width="12" px="8" color="primary_alpha_60" textAlign="center">
        <Container width="6" children={`Tipo: ${item.ticket.name}`} />
        <Container width="6" children={`Código: ${item.token}`} />
    </ContainerFlex>
}

const CrowdItemView = ({ item }) => {
    const [showDetails, setShowDetails] = useState(false);

    return <Container width="6" px="8">
        <Card flex={true} width="12" color="white" align="center">
            <ContainerFlex width="wrap" fontColor="black" ph="8" textAlign="center">
                <Container width="6" fontWeight="bold" children={item.username} />
                <Container width="6" children={`Nº entradas: ${item.tickets.length}`} />
            </ContainerFlex>
            <Button onclick={() => setShowDetails(!showDetails)} width="auto" color="colorAccent" fontColor="colorWhite" textAlign="center" fontWeight="bold" px="16" mx="4" children={<TextLocalized children="actionDetails" />} />
            {
                showDetails ?
                    <ContainerFlex width="12" pv="16">
                        {item.tickets.map(ticket => <CrowdTicketItemView item={ticket} />)}
                    </ContainerFlex>
                    : null
            }
        </Card>
    </Container>
}

const EventCrowd = ({ item }) => {
    const [crowds, setCrowds] = useState([...item.crowds]);

    return <Container width="12">
        <Container width="12" color="colorWhite" fontColor="white" px="32">
            <Container width="12" fontWeight="bold" fontSize="28px" pb="32" children={<TextLocalized children="msgCrowd" />} />
            {crowds.map(user => <CrowdItemView key={user.id} item={user} />)}
        </Container>
    </Container>
}

const EventDetailView = () => {
    const [tabIndex, setTabIndex] = useState(0)
    const tabFontWeight = (index) => tabIndex === index ? 300 : 100;

    let history = useHistory();

    return (
        <ContainerFlex width="12" minheight="calc(100vh - 92px)" align="stretch">

            <Container width="2" minheight="100%" px="16">

                <ContainerFlex width="12" ph="16" pb="32" align="center" fontWeight="bold" fontSize="28px">
                    <Button onclick={() => history.goBack()} mr="16" flex={true} width="auto" px="8" align="center" justify="center" fontColor="white" radius="50%" color="colorAccent" children={<Icon size={1} path={mdiArrowLeft} />} />
                </ContainerFlex>

                <Container px="16" textAlign="start" fontColor="white" fontWeight="bold" fontSize="24px" children={<TextLocalized children="msgSections" />} />

                <Button onclick={() => setTabIndex(0)} shadow={false} flex={true} align="center" pv="16" fontColor="white" hoverColor="colorPrimary" fontWeight={tabFontWeight(0)} radius="0px" radiustr="30px" radiusbr="30px">
                    <Icon path={mdiInformation} size="24px" style={{ margin: '0 16px' }} />
                    <TextLocalized children="msgInformation" />
                </Button>

                <Button onclick={() => setTabIndex(1)} shadow={false} flex={true} align="center" pv="16" fontColor="white" hoverColor="colorPrimary" fontWeight={tabFontWeight(1)} radius="0px" radiustr="30px" radiusbr="30px">
                    <Icon path={mdiTicket} size="24px" style={{ margin: '0 16px' }} />
                    <TextLocalized children="msgTickets" />
                </Button>

                <Button onclick={() => setTabIndex(5)} shadow={false} flex={true} align="center" pv="16" fontColor="white" hoverColor="colorPrimary" fontWeight={tabFontWeight(5)} radius="0px" radiustr="30px" radiusbr="30px">
                    <Icon path={mdiAccountGroup} size="24px" style={{ margin: '0 16px' }} />
                    <TextLocalized children="msgCrowd" />
                </Button>

                <Container mv="16" width="12" height="1px" color="white" />

                <Container ph="16" pb="8" textAlign="start" fontColor="white" fontWeight="bold" fontSize="24px" children={<TextLocalized children="msgActions" />} />

                <Button width="wrap" ph="16" pv="8" mv="8" textAlign="center" fontColor="white" fontWeight="bold" color="colorAccent" children={<TextLocalized children="actionPublish" />} />
            </Container>

            <ContainerFlex width="wrap" direction="column" minheight="100%" px="16">
                {tabIndex === 0 ? <EventInfo item={demo} /> : tabIndex === 1 ? <EventTickets item={demo} /> : <EventCrowd item={demo} />}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default EventDetailView;