
import { getUserToken, updateStorageUser, getStorageUser } from './UserUtil';

export const HttpOperations = {
    login: "login"
}

export const HttpMethod = {
    get: "GET",
    post: "POST",
    patch: "PATCH",
    delete: "DELETE"
}

export const HttpConnection = async (operation, success, error, method = "GET", data = null, checkingAuth = false) => {

    let token = getUserToken();

    const request = await fetch(`${serverUrlEndpoint}${operation}`, {
        method: method,
        body: data === null ? null : JSON.stringify(data),
        headers: {
            'Authorization': `${token === null || checkingAuth ? '' : token}`,
            'Content-Type': 'application/json',
        }
    })

    let item = Object()

    if (!request.ok) {
        if ((request.status === 401 || request.status === 403) && !checkingAuth) {

            var auxUser = getStorageUser();

            const params = {
                "username": auxUser.usuario.username,
                "password": auxUser.usuario.password,
            }

            HttpConnection(HttpOperations.token, (item) => {
                updateStorageUser(item);
                HttpConnection(operation, success, error, method, data, false);
            },
                (errorCode) => error(errorCode),
                HttpMethod.post, params, true);
        } else {
            error(request.status);
            console.log(request.status);
        }
    } else {
        try {
            item = await request.json();
        } catch (error) {
            item = {};
        }
        success(item);
    }
}

const debugMode = true;

const serverUrlEndpoint = debugMode ? "http://192.168.1.138:8084/swingersapp/api/" : "";
export const imageUrl = debugMode ? "http://192.168.1.138:8084/swingersapp/api/" : "";
